import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text, Button, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				kursin.eu – we help developers for free
			</title>
			<meta name={"description"} content={"Non-profit help to no-code and low-code developers"} />
			<meta property={"og:title"} content={"kursin.eu – we help no-code developers for free"} />
			<meta property={"og:description"} content={"Non-profit help to no-code and low-code developers"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6086791d123639001f37d0f1/images/k.png?v=2021-11-25T21:50:52.343Z"} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Box
				display="flex"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Link
					href="/index"
					color="--darkL2"
					text-decoration-line="initial"
					font="--headline3"
					padding="15px 0px 15px 0px"
					target="_self"
				>
					kursin.eu
				</Link>
			</Box>
		</Section>
		<Structure cells-number-total="1" cells-number-group="1" padding="80px 0px 80px 0px">
			<Override slot="Content">
				<Override slot="cell-0">
					{"            "}
					<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
						404
					</Text>
					{"            "}
					<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
						Nothing to see here, please go to the homepage
					</Text>
					{"            "}
					<Button
						font="--lead"
						margin="20px"
						type="link"
						text-decoration-line="initial"
						target="_self"
						href="/index"
					>
						Take me to the homepage
					</Button>
					{"        "}
				</Override>
				<Override
					slot="Cell 0th"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
				/>
				{"        "}{"        "}{"    "}
			</Override>
			{"    "}
		</Structure>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script place={"endOfHead"} rawKey={"619ff67976da98ae2bd8d82e"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-WZN4GKH');"}
			</script>
			<script place={"endOfBody"} rawKey={"619ff6910c47c956e81fb597"}>
				{"<noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-WZN4GKH\"\nheight=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>"}
			</script>
		</RawHtml>
	</Theme>;
});